import axios from "axios";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../component/Sidebar";
import { getToken, getCurrentFullSession } from "../../../utility/utilityFunctions";

const FinanceOneReport = () => {
  const today = new Date().toISOString().substr(0, 10);

  const [search, setSearch] = useState("");

  const [financeOneReports, setFinanceOneReports] = useState([]);

  const [branches, setBranches] = useState([]);
  const [commonGrades, setCommonGrades] = useState([]);

  const [branch, setBranch] = useState("all");
  const [Session, setSession] = useState(getCurrentFullSession());
  const [sessions, setSessions] = useState([]);
  const [grade, setGrade] = useState("all");
  const [totalRecords, setRecords] = useState(0);
  const [totalAmount, setAmount] = useState(0);
  const [date, setDate] = useState(today);
  const [dueAmount, setDueAmount] = useState(0);

  const [filterClicked, setFilterClicked] = useState(0);

  // const [endDate, setEndDate] = useState(today);
  const [fullOrPartialPaid, setFullOrPartialPaid] = useState("full");
  const [percentOrFixed, setPercentOrFixed] = useState("fixed");
  let userInfo = localStorage.getItem("user");
  userInfo = JSON.parse(userInfo);

  // Define a function to format the date
  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note that months are zero-indexed, so we add 1
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    document.getElementsByClassName("table-responsive")[0].style.display =
      "none";

    document.getElementsByClassName("loader")[0].style.display = "block";

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/financeOneReportScreen`,
        {
          branch:
            userInfo?.role !== "admin" && userInfo?.role !== "financial_head"
              ? userInfo.branch[0]._id
              : branch,
          Session,
          grade,
          fullOrPartialPaid,
          percentOrFixed,
          date,
          dueAmount,
          search,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        document.getElementsByClassName("loader")[0].style.display = "none";

        document.getElementsByClassName("table-responsive")[0].style.display =
          "block";
        const { financeReportData, branches, commonGrades, sessions } =
          response.data;
        setBranches(branches);
        setCommonGrades(commonGrades);
        setFinanceOneReports(financeReportData);
        // console.log("financeReportData:", financeReportData);
        setRecords(financeReportData.length);
        setSessions(sessions);
      });
  }, [filterClicked, search, Session]);

  const handleExport = () => {
    const table = document.getElementById("myTable");
    const workbook = XLSX.utils.table_to_book(table);
    XLSX.writeFile(workbook, "finance.xlsx");
  };

  // const onUpdate = (e) => {
  //   const key = e.target.id;
  //   const value = e.target.value;
  //   console.log(key, value);
  // };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>

            <div style={{ backgroundColor: "white", padding: "25px" }}>
              <div className="">
                <h1
                  className="page-title"
                  style={{ marginLeft: "12px", marginBottom: "15px" }}
                >
                  Finance Report
                </h1>
                <div className="col-md-6 d-flex flex-wrap">
                  <div className="">
                    {userInfo?.role !== "admin" &&
                    userInfo?.role !== "financial_head" ? (
                      ""
                    ) : (
                      <div className="" style={{ marginRight: "15px" }}>
                        <select
                          className="form-control"
                          name=""
                          value={branch}
                          id="branch"
                          onChange={(e) => {
                            setBranch(e.target.value);
                          }}
                        >
                          <option value="all">All Branches</option>
                          {branches.map((branch) => (
                            <option value={branch._id.toString()}>
                              {branch?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>

                  <div className="" style={{ marginRight: "15px" }}>
                    <select
                      className="form-control"
                      name=""
                      value={grade}
                      id="grade"
                      onChange={(e) => {
                        setGrade(e.target.value);
                      }}
                    >
                      <option value="all">All Grades</option>
                      {commonGrades.map((data) => (
                        <option value={data._id}>{data.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="" style={{ marginRight: "15px" }}>
                    <select
                      className="form-control"
                      name=""
                      value={Session}
                      id="Session"
                      onChange={(e) => {
                        setSession(e.target.value);
                      }}
                    >
                      <option disabled selected>Select Session</option>
                      {sessions.map((session) => {
                        return (
                          <option value={session.session_name}>
                            {session.session_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div
                    className="material-switch d-flex align-items-center mt-4"
                    style={{ margin: "0px 5px" }}
                  >
                    <div style={{ marginRight: "10px" }}>Fully Paid</div>
                    <input
                      type="radio"
                      id="partial"
                      defaultChecked
                      onChange={(e) => {
                        setFullOrPartialPaid("full");
                      }}
                      name="fullOrPartialPaid"
                    />
                    <div style={{ marginRight: "10px" }}>Due</div>
                    <input
                      type="radio"
                      id="full"
                      onChange={(e) => {
                        setFullOrPartialPaid("partial");
                      }}
                      name="fullOrPartialPaid"
                    />
                  </div>
                </div>
              </div>

              <div
                className="mt-3"
                style={
                  fullOrPartialPaid == "full"
                    ? { display: "none" }
                    : { display: "flex" }
                }
              >
                <div className="col-md-6">
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setPercentOrFixed(e.target.value);
                    }}
                  >
                    <option value="fixed">Fixed</option>
                    {/* <option value="percent">Percent</option> */}
                  </select>
                </div>

                <div className="col-md-6">
                  <input
                    type="number"
                    className="form-control"
                    value={dueAmount}
                    onChange={(e) => {
                      setDueAmount(e.target.value);
                    }}
                    placeholder={percentOrFixed == "fixed" ? "Due Amount" : "%"}
                  />
                </div>
              </div>

              <div
                className="mt-3"
                style={
                  fullOrPartialPaid == "full"
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <div className="col-md-6">
                  <label htmlFor="start_date">Date</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                    value={date}
                    name=""
                    id="date"
                  />
                </div>

                {/* <div className="col-md-6">
                  <label htmlFor="start_date">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    value={endDate}
                    name=""
                    id="end_date"
                  />
                </div> */}
              </div>

              <div className="mt-3" style={{ padding: "5px 12px" }}>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={(e) => {
                    setFilterClicked((prevCount) => prevCount + 1);
                  }}
                >
                  <i class="side-menu__icon fe fe-filter" /> Filter
                </button>
                <button
                  className="btn btn-sm btn-green"
                  style={{ marginLeft: "5px" }}
                  onClick={handleExport}
                >
                  Export Data
                </button>

                <input
                  className="p-1 rounded-sm"
                  style={{ marginLeft: "5px" }}
                  type="text"
                  name="search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  placeholder="Search"
                />
              </div>

              <div style={{ padding: "15px" }}>
                <div className="mt-3">Total Records : {totalRecords}</div>
                {/* <div className="mt-3">Total Amount : {totalAmount}</div> */}
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div class="loader" style={{ display: "none" }}>
                          <h2>Hold On !!</h2>
                          <h3>We are generating report....</h3>
                          <div class="spinner-border text-danger" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div className="table-responsive">
                          {totalRecords > 0 ? (
                            <>
                              {fullOrPartialPaid == "full" ? (
                                <h1 className="my-5">{`Students who have fully paid the fee till ${formatDateString(
                                  date
                                )}`}</h1>
                              ) : (
                                <h1 className="my-5">
                                  Students with an outstanding balance of
                                  &#8377; {dueAmount} or more.
                                </h1>
                              )}
                              <table
                                className="table table-bordered border text-nowrap mb-0"
                                id="myTable"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Serial No.</b>
                                    </th>
                                    <th>
                                      <b>Sr. No.</b>
                                    </th>
                                    <th>
                                      <b>Name</b>
                                    </th>
                                    <th>
                                      <b>Amount</b>
                                    </th>
                                    <th>
                                      <b>Father/Guardian Name</b>
                                    </th>
                                    <th>
                                      <b>Mobile</b>
                                    </th>
                                    <th>
                                      <b>Branch</b>
                                    </th>
                                    <th>
                                      <b>Grade</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {financeOneReports.map(
                                    (financeOneReport, key) => {
                                      return (
                                        <tr key={financeOneReport._id}>
                                          <td>{key + 1}</td>

                                          <td>{financeOneReport.sr_no}</td>
                                          <td>
                                            <Link
                                              to={`/admin/students/${financeOneReport._id}`}
                                            >
                                              {financeOneReport.name}
                                            </Link>
                                            <br></br>
                                            <span class="badge bg-warning badge-sm  me-1 mb-1 mt-1 ml-10">
                                              <Link
                                                to={`/admin/collect-fee/${financeOneReport._id}`}
                                              >
                                                <span class="text-white">
                                                <i class="icon icon-wallet ml-3" />{" "}

                                                Collect fee
                                              </span>
                                              </Link>
                                            </span>
                                          </td>
                                          <td>
                                            &#8377;{financeOneReport.amount}
                                          </td>
                                          <td>
                                            {financeOneReport.father_name}
                                          </td>
                                          <td>{financeOneReport.mobile}</td>
                                          <td>{financeOneReport.branch}</td>
                                          <td>{financeOneReport.grade}</td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </>
                          ) : (
                            <h1>No record found.</h1>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinanceOneReport;
