import axios from "axios";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  firstDateOfMonth,
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

const TeacherReport2 = () => {
  const today = new Date().toISOString().substr(0, 10);

  const [teacherAttendanceReports, setTeacherAttendanceReports] = useState([]);
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState("63e63d833aebb6c733ccfa8f");
  const [showDatesOfId, setShowDatesOfId] = useState(null);
  const [reason, setReason] = useState("both");
  // const [leaveOrLate, setLeaveOrLate] = useState("half");
  const [report_type, setReportType] = useState("half");
  const [status, setStatus] = useState("both");

  const [totalRecords, setRecords] = useState(0);
  const [leavesCount, setLeavesCount] = useState(0);

  const [filterClicked, setFilterClicked] = useState(0);
  const [start_date, setStartDate] = useState(firstDateOfMonth);
  const [end_date, setEndDate] = useState(today);

  let userInfo = localStorage.getItem("user");
  userInfo = JSON.parse(userInfo);
  useEffect(() => {
    document.getElementsByClassName("table-responsive")[0].style.display =
      "none";

    document.getElementsByClassName("loader")[0].style.display = "block";

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/getTeachersRecords`,
        {
          // branch,
          branch: userInfo?.role !== "admin" ? userInfo.branch[0]._id : branch,

          start_date,
          end_date,
          leavesCount,
          reason,
          // leaveOrLate,
          report_type,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        document.getElementsByClassName("loader")[0].style.display = "none";
        document.getElementsByClassName("table-responsive")[0].style.display =
          "block";
        const { teacherReportData, branches } = response.data;
        setBranches(branches);
        setTeacherAttendanceReports(teacherReportData);
        setRecords(teacherReportData?.length ? teacherReportData?.length : 0);
        console.log("teacherReportData:", teacherReportData);
      });
  }, [filterClicked]);

  const handleExport = () => {
    const table = document.getElementById("myTable");
    const workbook = XLSX.utils.table_to_book(table);
    XLSX.writeFile(workbook, "teacherAttendances.xlsx");
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="mb-2">
              <GoBackButton />
            </div>

            <div style={{ backgroundColor: "white", padding: "25px" }}>
              <div className="">
                <h1
                  className="page-title"
                  style={{ marginLeft: "12px", marginBottom: "15px" }}
                >
                  Teacher Attendance Report
                </h1>
              </div>

              <div
                className="d-flex align-items-center"
                style={{ margin: "10px 25px" }}
              >
                <input
                  type="radio"
                  id="half"
                  defaultChecked
                  onChange={(e) => {
                    setReportType("half");
                  }}
                  name="fullOrPartialPaid"
                />
                <div style={{ marginRight: "10px" }}>Half day</div>

                <input
                  type="radio"
                  id="full"
                  onChange={(e) => {
                    setReportType("full");
                  }}
                  name="fullOrPartialPaid"
                />
                <div style={{ marginRight: "10px" }}>Full Day</div>

                
                <input
                  type="radio"
                  id="irregular-times"
                  onChange={(e) => {
                    setReportType("irregular-times");
                  }}
                  name="fullOrPartialPaid"
                />
                <div style={{ marginRight: "10px" }}>Irregular Timings</div>

                <div className="col-md-4 d-flex flex-wrap">
                  {userInfo?.role !== "admin" ? (
                    ""
                  ) : (
                    <>
                      {" "}
                      <div className="">
                        <select
                          className="form-control"
                          name=""
                          value={branch}
                          id="branch"
                          onChange={(e) => {
                            console.log(e.target);
                            setBranch(e.target.value);
                          }}
                        >
                          <option value="all">All Branches</option>
                          {branches.map((branch) => (
                            <option value={branch._id.toString()}>
                              {branch.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="col-md-12 d-flex">

                <div className="col-md-4">
                  <label htmlFor="start_date">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    value={start_date}
                    name=""
                    id="start_date"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="end_date">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    value={end_date}
                    name=""
                    id="end_date"
                  />
                </div>
              </div>

              <div className="mt-3" style={{ padding: "5px 12px" }}>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={(e) => {
                    setFilterClicked((prevCount) => prevCount + 1);
                  }}
                >
                  <i className="side-menu__icon fe fe-filter" /> Filter
                </button>
                {/* <button
                  className="btn btn-sm btn-green"
                  style={{ marginLeft: "5px" }}
                  onClick={handleExport}
                >
                  Export Data
                </button> */}
              </div>

              <div style={{ padding: "15px" }}>
                <div className="mt-3">Total Records : {totalRecords}</div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="loader" style={{ display: "none" }}>
                          <h2>Hold On !!</h2>
                          <h3>We are generating report....</h3>
                          <div
                            className="spinner-border text-danger"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div className="table-responsive">
                          {totalRecords > 0 ? (
                            <>
                              <table
                                className="table table-bordered border text-nowrap mb-0"
                                id="myTable"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <b>SR. NO</b>
                                    </th>
                                    <th>
                                      <b>Name</b>
                                    </th>
                                    <th>
                                      <b>Mobile</b>
                                    </th>
                                    <th>
                                      <b>Branch</b>
                                    </th>
                                    <th>
                                      <b>Count</b>
                                    </th>
                                    <th>
                                      <b>Date</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {teacherAttendanceReports.map(
                                    (teacherAttendanceReport, index) => {
                                      return (
                                        <tr
                                          key={
                                            index
                                          }
                                        >
                                          <td>
                                            {index+1}
                                          </td>
                                          <td>
                                            <Link
                                              target="blank"
                                              to={`/admin/teachers/${teacherAttendanceReport._id}`}
                                            >
                                              {teacherAttendanceReport.name}
                                            </Link>
                                          </td>
                                          <td>
                                            {teacherAttendanceReport.mobile}
                                          </td>
                                          <td>
                                            {teacherAttendanceReport.branch}
                                          </td>
                                          <td
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            data-html="true"
                                            title="Tooltip <br> on top"
                                          >
                                            {teacherAttendanceReport.count}
                                          </td>
                                          <td>
                                            {showDatesOfId &&
                                            showDatesOfId == "date_" + index ? (
                                              <ul id={"date_" + index}>
                                                {teacherAttendanceReport?.dates
                                                  ?.reverse()
                                                  ?.map((data) => (
                                                    <li>{data}</li>
                                                  ))}
                                              </ul>
                                            ) : (
                                              <button
                                                className="btn btn-green"
                                                onClick={() => {
                                                  setShowDatesOfId(
                                                    "date_" + index
                                                  );
                                                }}
                                              >
                                                Show
                                              </button>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </>
                          ) : (
                            <h1>No record found.</h1>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherReport2;
