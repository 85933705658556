import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import { getToken, getCurrentFullSession } from "../../utility/utilityFunctions";
import { Link } from "react-router-dom";
import GoBackButton from "../../component/GoBackButton";

function Mark2() {
  const { id } = useParams();
  const [exams, setExams] = useState(null);
  const [branchList, setbranchList] = useState([]); 
  const [selectedBranch, setSelectedBranch] = useState('63e63d833aebb6c733ccfa8f');
  const [session, setSession] = useState(getCurrentFullSession());
  const [sessions, setSessions] = useState([]);
  const [testType, setTestType] = useState('');
  const [testTypes, setTestTypes] = useState([]);
  let count = 1;

  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0];
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/getMark2`;
    axios
      .post(apiUrl, {
        session,
        selectedBranch,
        testType
      },{
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { allStudentMarkWithExam, sessions, testTypes } = response.data;
        const { branchList } = response.data;
        setbranchList(branchList);
        setExams(allStudentMarkWithExam);
        setSessions(sessions);
        setTestTypes(testTypes);
        // console.log("allStudentMarkWithExam", allStudentMarkWithExam);
        if (loaderElement) {
          // If the loader element exists, you can modify its display style
          loaderElement.style.display = "none"; // or "block" or any other desired value
        }
      });
  }, [session, selectedBranch, testType]);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="mb-2">
              <GoBackButton />
            </div>
            <div className="col-sm-12 card pb-2 pt-2">
              <div className="col-sm-12">
                <div className="page-header">
                  <h1 className="page-title p-2">Exams List</h1>
                  {/* <div class="alert alert-info" role="alert">
                    Students those were promoted from last class and paid less
                    than 3000 INR.
                  </div> */}
                  <div className="col-sm-4 mx-2 form-inline" >
                    <select class="form-control mx-2"
                        id="testTypeFilter"
                        name="testTypeFilter"
                        onChange={(e) => {
                          setTestType(e.target.value);
                        }}
                      >
                      <option value=''>All Test Type</option>
                        {testTypes.map((item, index) => {
                          const [key, value] = Object.entries(item)[0]; // Extract key-value pair
                          return (
                            <option value={key}>
                              <span>{value}</span>
                            </option>
                          );
                        })}
                      </select>
                    <select class="form-control mx-2"
                        id="branchFilter"
                        name="branchFilter"
                        value={selectedBranch}
                        onChange={handleBranchChange}
                      >
                        {branchList.map(branch => (
                          <option key={branch._id} value={branch._id}>
                            {branch.name}
                          </option>
                        ))}
                      </select>
                      <select
                        className="form-control"
                        name=""
                        value={session}
                        onChange={(e) => {
                          setSession(e.target.value);
                        }}
                      >
                        {sessions.map((session) => {
                          return (
                            <option value={session.session_name}>
                              {session.session_name}
                            </option>
                          );
                        })}
                      </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-4">
                <div className="loader" style={{ display: "none" }}>
                  <h2>Hold On !!</h2>
                  <h3>We are generating report....</h3>
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              {exams ? (
                <div className="row ">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="table-responsive">
                              <table
                                className="table table-bordered border text-nowrap mb-0"
                                id="basic-edit"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Sr. No</b>
                                    </th>
                                    <th>
                                      <b>Exam</b>
                                    </th>
                                    <th>
                                      <b>Exam Marks</b>
                                    </th>
                                    <th>
                                      <b>Grade</b>
                                    </th>

                                    <th>
                                      <b>Subject</b>
                                    </th>
                                    <th>
                                      <b>Students</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {exams.map((exam) => {
                                    return (
                                      <React.Fragment key={exam._id}>
                                        {/* First <tr> for basic teacher information */}
                                        {exam.exam_id ? (

                                        <tr>
                                          <td>{count++}</td>

                                          <td>
                                            <Link
                                              to={`/admin/getStudentMarksAdmin/${exam?.exam_id?._id}/${exam?.common_grade._id}/${exam?.subject_id._id}/${exam?.branch}`}
                                            >
                                              {exam?.exam_id?.name}
                                            </Link>
                                          </td>

                                          <td>{exam?.total_marks}</td>

                                          <td>{exam?.common_grade?.name}</td>
                                          <td>{exam?.subject_id?.name}</td>
                                          <td>{exam?.students.length}</td>
                                        </tr>
                                        ) : (
                                          ""
                                        )}

                                      </React.Fragment>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Mark2;
