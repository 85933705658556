import axios from "axios";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../component/Sidebar";
import { getToken } from "../../../utility/utilityFunctions";

const StudentOneReport = () => {
  const today = new Date().toISOString().substr(0, 10);

  const [studentOneReport, setStudentOneReports] = useState([]);

  const [branches, setBranches] = useState([]);
  const [commonGrades, setCommonGrades] = useState([]);

  const [branch, setBranch] = useState("all");
  const [status, setStatus] = useState("registered");
  const [grade, setGrade] = useState("all");
  const [isRteNonRte, setIsRteNonRte] = useState("all");

  const [totalRecords, setRecords] = useState(0);

  const [filterClicked, setFilterClicked] = useState(0);

  const [start_date, setStartDate] = useState(today);
  const [end_date, setEndDate] = useState(today);
  const [specificStudentFilter, setSpecificStudentFilter] = useState("none");

  useEffect(() => {
    document.getElementsByClassName("table-responsive")[0].style.display =
      "none";

    document.getElementsByClassName("loader")[0].style.display = "block";

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/studentOneReportScreen`,
        {
          branch,
          status,
          grade,
          specificStudentFilter,
          start_date,
          end_date,
          isRteNonRte
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        document.getElementsByClassName("loader")[0].style.display = "none";

        document.getElementsByClassName("table-responsive")[0].style.display =
          "block";
        const { studentReportData, branches, commonGrades } = response.data;
        setBranches(branches);
        setCommonGrades(commonGrades);
        setStudentOneReports(studentReportData);
        setRecords(studentReportData?.length ? studentReportData?.length : 0);
      });
  }, [filterClicked]);

  const handleExport = () => {
    const table = document.getElementById("myTable");
    const workbook = XLSX.utils.table_to_book(table);
    XLSX.writeFile(workbook, "student.xlsx");
  };

  // const onUpdate = (e) => {
  //   const key = e.target.id;
  //   const value = e.target.value;
  //   console.log(key, value);
  // };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>

            <div style={{ backgroundColor: "white", padding: "25px" }}>
              <div className="">
              <h1 className="page-title" style={{marginLeft : "12px", marginBottom : "15px"}}>Student Report</h1>
                <div className="col-md-8 d-flex flex-wrap">
                  <div className="">
                    <select
                      className="form-control"
                      name=""
                      value={branch}
                      id="branch"
                      onChange={(e) => {
                        console.log(e.target);
                        setBranch(e.target.value);
                      }}
                    >
                      <option value="all">All Branches</option>
                      {branches.map((branch) => (
                        <option value={branch._id.toString()}>
                          {branch.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="">
                    <select
                      className="form-control"
                      name=""
                      value={grade}
                      id="grade"
                      // disabled={}
                      onChange={(e) => {
                        setGrade(e.target.value);
                      }}
                    >
                      <option value="all">All Grades</option>
                      {commonGrades.map((data) => (
                        <option value={data._id}>{data.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="">
                    <select
                      className="form-control"
                      name=""
                      value={status}
                      disabled={specificStudentFilter == "none" ? false : true}
                      id="status"
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      <option value="none" disabled>
                        Select Status
                      </option>
                      <option value="enquired">Enquired</option>
                      <option value="paid-enquired">Paid Enquired</option>
                      <option value="registered">Registered</option>
                      <option value="promoted">Promoted</option>
                      <option value="rp">Registered + Promoted</option>
                      <option value="completed">Completed</option>
                    </select>
                  </div>

                  <div className="">
                    <select
                      className="form-control"
                      name=""
                      value={isRteNonRte}
                      id="isRteNonRte"
                      onChange={(e) => {
                        setIsRteNonRte(e.target.value);
                      }}
                    >
                      <option value="all">
                        RTE + NON RTE
                      </option>
                      <option value="rte">RTE</option>
                      <option value="nonrte">NON RTE</option>
                    </select>
                  </div>

                  <div
                    className="material-switch d-flex align-items-center mt-4"
                    style={{ margin: "0px 5px" }}
                  >
                    <div style={{ marginRight: "10px" }}>
                      <span className="badge bg-danger badge-sm  me-1 mb-1 mt-1 ml-10">
                        Advance Filter
                      </span>
                    </div>

                    <div style={{ marginRight: "10px" }}>None</div>
                    <input
                      type="radio"
                      id="specific-filter-none"
                      defaultChecked
                      onChange={(e) => {
                        setStatus("registered");
                        setSpecificStudentFilter("none");
                      }}
                      name="specificStudentFilter"
                    />
                    <div style={{ marginRight: "10px" }}>New Admissions</div>
                    <input
                      type="radio"
                      id="specific-filter-new-admissions"
                      onChange={(e) => {
                        setStatus("none");
                        setSpecificStudentFilter(
                          "specific-filter-new-admissions"
                        );
                      }}
                      name="specificStudentFilter"
                    />
                    <div style={{ marginRight: "10px" }}>Total Withdrawl</div>
                    <input
                      type="radio"
                      id="specific-filter-withdrawl"
                      onChange={(e) => {
                        setStatus("none");
                        setSpecificStudentFilter("specific-filter-withdrawl");
                      }}
                      name="specificStudentFilter"
                    />
                  </div>
                </div>               
              </div>

              <div
                className="mt-3"
                style={
                  specificStudentFilter == "none"
                    ? { display: "none" }
                    : { display: "flex" }
                }
              >
                <div className="col-md-12 d-flex">
                  <div className="col-md-6">
                    <label htmlFor="start_date">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                      value={start_date}
                      name=""
                      id="start_date"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="end_date">End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                      value={end_date}
                      name=""
                      id="end_date"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3" style={{ padding: "5px 12px" }}>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={(e) => {
                    setFilterClicked((prevCount) => prevCount + 1);
                  }}
                >
                  <i className="side-menu__icon fe fe-filter" /> Filter
                </button>
                <button
                  className="btn btn-sm btn-green"
                  style={{ marginLeft: "5px" }}
                  onClick={handleExport}
                >
                  Export Data
                </button>
              </div>

              <div style={{ padding: "15px" }}>
                <div className="mt-3">Total Records : {totalRecords}</div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="loader" style={{ display: "none" }}>
                          <h2>Hold On !!</h2>
                          <h3>We are generating report....</h3>
                          <div
                            className="spinner-border text-danger"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div className="table-responsive">
                          {totalRecords > 0 ? (
                            <>
                              {specificStudentFilter == "none" ? (
                                <h1 className="my-5">Filtered Results</h1>
                              ) : (
                                <h1 className="my-5">{``}</h1>
                              )}
                              <table
                                className="table table-bordered border text-nowrap mb-0"
                                id="myTable"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Name</b>
                                    </th>
                                    <th>
                                      <b>Father/Guardian Name</b>
                                    </th>
                                    <th>
                                      <b>Mobile</b>
                                    </th>
                                    <th>
                                      <b>SR. NO</b>
                                    </th>
                                    <th>
                                      <b>Branch</b>
                                    </th>
                                    <th>
                                      <b>Grade</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {studentOneReport.map((studentOneReport) => {
                                    return (
                                      <tr key={studentOneReport._id}>
                                        <td><Link target="blank" to={`/admin/students/${studentOneReport._id}`}>{studentOneReport.name}</Link></td>
                                        <td>{studentOneReport.father_name}</td>
                                        <td>{studentOneReport.mobile}</td>
                                        <td>{studentOneReport.sr_no}</td>
                                        <td>{studentOneReport.branch}</td>
                                        <td>{studentOneReport.grade}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </>
                          ) : (
                            <h1>No record found.</h1>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentOneReport;
